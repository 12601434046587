import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import AboutMe from "../Components/Home/AboutMe";
import HeroSection from "../Components/Home/HeroSection";
import PreviewSection from "../Components/Home/PreviewSection";
import SmoothScrolling from '../Components/SmoothScrolling';
import "./Home.css";
import { isMobile } from "react-device-detect";

export default function Home() {
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    });

    const [cursorVariant, setCursorVariant] = useState("default");


    useEffect(() => {
        const mouseMove = (e: { clientX: any; clientY: any; }) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            });
        }

        window.addEventListener("mousemove", mouseMove);

        return () => {
            window.removeEventListener("mousemove", mouseMove);
        }
    }, []);

    const variants = {
        default: {
            x: mousePosition.x - 16,
            y: mousePosition.y - 16,
            transition: {
                mode: "smooth",
                duration: 0,
            }
        },
        link: {
            height: 10,
            width: 10,
            x: mousePosition.x - 5,
            y: mousePosition.y - 5,
            transition: {
                mode: "smooth",
                duration: 0
            }
        },
        dark: {
            height: 10,
            width: 10,
            x: mousePosition.x - 5,
            y: mousePosition.y - 5,
            transition: {
                mode: "smooth",
                duration: 0,
            },
            backgroundColor: "#8c8782"
        }
    }

    const cursorLinkEnter = () => setCursorVariant("link");
    const cursorDarkEnter = () => setCursorVariant("dark");
    const cursorLeave = () => setCursorVariant("default");
    return (
        <>
            <SmoothScrolling>
                <HeroSection cursorEnter={cursorLinkEnter} cursorLeave={cursorLeave} />
                <AboutMe cursorEnter={cursorDarkEnter} cursorLeave={cursorLeave} />
                {isMobile &&
                <div className="scroll-cover"/>
                }
                <PreviewSection cursorLinkEnter={cursorLinkEnter} cursorDarkEnter={cursorDarkEnter} cursorLeave={cursorLeave} />
                {!isMobile &&
                    <motion.div
                        className='cursor'
                        variants={variants}
                        animate={cursorVariant}
                    />
                }
            </SmoothScrolling>
        </>
    );
}