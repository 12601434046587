import './Banner.css'

interface BannerProps {
    text: string[];
    speed: number;
    size: number;
    color: string;
    dividerColor: string;
}

export function TextSection({ text, speed, size, color, dividerColor }: BannerProps) {
    const bannerStyle = {
        display: 'flex',
        animation: `swipe ${speed}ms linear infinite`,
    };

    return (
        <div className='text' style={bannerStyle}>
            {text.map((string) => (
                <div>
                    <h1 style={{fontSize : size, color: color, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{string}</h1>
                    <h1 style={{fontSize : size, color: dividerColor, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{"//"}</h1>
                </div>
            ))}
            {text.map((string) => (
                <div>
                    <h1 style={{fontSize : size, color: color, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{string}</h1>
                    <h1 style={{fontSize : size, color: dividerColor, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{"//"}</h1>
                </div>
            ))}
            {text.map((string) => (
                <div>
                    <h1 style={{fontSize : size, color: color, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{string}</h1>
                    <h1 style={{fontSize : size, color: dividerColor, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{"//"}</h1>
                </div>
            ))}
            {text.map((string) => (
                <div>
                    <h1 style={{fontSize : size, color: color, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{string}</h1>
                    <h1 style={{fontSize : size, color: dividerColor, fontFamily: "Staatliches", fontWeight:"lighter", display: "inline"}}>{"//"}</h1>
                </div>
            ))}
        </div>
    );
}

export default function TextBanner({ text, speed, size, color, dividerColor }: BannerProps) {
    return (
        <div className='text-banner-wrapper'>
            <div className='wrapper'>
                <TextSection text={text} speed={speed} size={size} color={color} dividerColor={dividerColor}/>
            </div>
        </div>
    );
}