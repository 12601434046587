import { IconButton, ThemeProvider } from '@mui/material';
import './HeroSection.css';
import { FreeBreakfast, Instagram, Mail, X, Collections } from '@mui/icons-material';
import { createTheme } from '@mui/material';
import { teal } from '@mui/material/colors';
import { motion, useAnimate, useMotionValueEvent, useScroll } from 'framer-motion';
import Banner from './Banner';
import React, { useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { isMobile } from 'react-device-detect';

const theme = createTheme({
    palette: {
        primary: {
            main: '#f9f0e8',
        },
        secondary: teal,
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: "#f9f0e850"
                    }
                }
            }
        }
    }
})

var cartParallaxSpeed = isMobile ? 15 : 30;

const cloudImg = [
    {
        src: "/images/train/cloud.png",
        name: "cloud"
    }
]

const waterImg = [
    {
        src: "/images/train/water.png",
        name: "water"
    }
]

const pole1Img = [
    {
        src: "/images/train/poles1.png",
        name: "water"
    }
]

const pole2Img = [
    {
        src: "/images/train/poles2.png",
        name: "water"
    }
]

const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
}

export default function HeroSection(props: {
    cursorEnter: React.MouseEventHandler<HTMLDivElement> | undefined;
    cursorLeave: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
    const { scrollYProgress } = useScroll();
    const [scope, animate] = useAnimate();
    const [hidden, setHidden] = React.useState(false);

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        latest > 0 ? setHidden(true) : setHidden(false);
    })

    async function bounceAnimation() {
        await animate(scope.current, { opacity: 0 });
        await animate(scope.current, { opacity: 1 }, { delay: 2 });
        animate(
            scope.current,
            {
                y: ["0%", "100%", "0%"]
            },
            {
                repeat: Infinity,
                ease: "easeInOut",
                duration: 2
            }
        )
    }

    useEffect(() => {
        bounceAnimation();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="hero-container" onMouseEnter={props.cursorLeave}>
            <img className="hero" src="/images/train/sky.png" alt="Hero Banner" />
            <Banner images={waterImg} speed={10000} />
            <Banner images={cloudImg} speed={100000} />
            <Banner images={pole1Img} speed={900} />
            <Banner images={pole2Img} speed={950} />

            <Parallax style={{ position: 'absolute', margin: "10% 0 0 0" }} speed={cartParallaxSpeed}>
                <img className="hero" src="/images/train/bannerBottom.png" alt="Hero Banner" />
            </Parallax>

            <Parallax style={{ position: 'absolute' }} speed={cartParallaxSpeed}>
                <img className="hero" src="/images/train/kart.png" alt="Hero Banner" />
            </Parallax>

            {!isMobile &&
                <Parallax style={{ position: 'absolute' }} speed={cartParallaxSpeed * 2}>
                    <video className="hero" src="/images/train/handles.webm" autoPlay loop muted playsInline />
                </Parallax>
            }

            <Parallax className="hero-container" style={{ position: 'absolute' }} speed={0}>
                <motion.h1
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                >hello</motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                >i'm oogy</motion.p>
                <ThemeProvider theme={theme}>
                    <motion.div
                        className="container-side-by-side"
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5 }}
                        onMouseEnter={props.cursorEnter}
                        onMouseLeave={props.cursorLeave}
                    >
                        <IconButton href="https://twitter.com/spoogyoogie" target="_blank" aria-label="twitter" size="large" color="primary" style={{ cursor: "none" }}>
                            <X fontSize="large" />
                        </IconButton>
                        <IconButton href="https://www.instagram.com/spoogyoogie/" target="_blank" aria-label="instagram" size="large" color="primary" style={{ cursor: "none" }}>
                            <Instagram fontSize="large" />
                        </IconButton>
                        <IconButton href="https://ko-fi.com/spoogyoogie" target="_blank" aria-label="kofi" size="large" color="primary" style={{ cursor: "none" }}>
                            <FreeBreakfast fontSize="large" />
                        </IconButton>
                        <IconButton href="https://www.pixiv.net/en/users/32040454" target="_blank" aria-label="mail" size="large" color="primary" style={{ cursor: "none" }}>
                            <Collections fontSize="large" />
                        </IconButton>
                        <IconButton href="mailto:spoogyoogie@gmail.com" target="_blank" aria-label="mail" size="large" color="primary" style={{ cursor: "none" }}>
                            <Mail fontSize="large" />
                        </IconButton>
                    </motion.div>
                </ThemeProvider>
            </Parallax>

            <motion.div
                className="triangle-container"
                variants={variants}
                animate={hidden ? "hidden" : "visible"}
                transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
            >
                <motion.div
                    ref={scope}
                    className='triangle-down'
                />
            </motion.div>
        </div>
    );
}