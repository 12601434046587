import { Button } from "@mui/material";
import { motion } from "framer-motion";
import "./PreviewSectionItem.css";

interface ItemProps {
    onMouseEnter: React.MouseEventHandler<HTMLDivElement> | undefined;
    onMouseLeave: React.MouseEventHandler<HTMLDivElement> | undefined;
    title: string;
    description: string;
    date: string;
    imagePath: string;
    url: string;
    buttonLabel: string;
    buttonEnable: boolean;
}

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 }
}

export default function PreviewSectionItem({ onMouseEnter, onMouseLeave, title, description, date, imagePath, url, buttonLabel, buttonEnable }: ItemProps) {
    var divStyle = {
        backgroundImage: 'linear-gradient(to bottom, rgba(31, 30, 28, 0), rgba(31, 30, 28, 0), rgba(31, 30, 28, 0.8)), url(' + imagePath + ')',
        height: "40rem",
        backgroundColor: "beige",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: "20px",
        margin: "3rem 10% 0 10%"
    }

    if (buttonEnable) {
        return (
            <motion.div
                variants={variants}
                initial="hidden"
                whileInView="visible"
                transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 1 }}
                viewport={{ margin: "15% 0% -10% 0%" }}
            >
                <div className="info-section" style={divStyle}>
                    <div className="info-wrapper">
                        <div className="wrapper">
                            <h1>{title}</h1>
                            <h2>{date}</h2>
                            <p>{description}</p>
                            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                <Button variant="outlined" href={url} target="_blank" sx={{
                                    cursor: "none",
                                    color: "#f9f0e8", borderColor: "#f9f0e8", borderWidth: "2px", '&:hover': {
                                        cursor: "none",
                                        backgroundColor: '#f9f0e8',
                                        borderWidth: "2px",
                                        borderColor: "#f9f0e8",
                                        color: "#1f1e1c"
                                    }, fontFamily: "Staatliches", width: "100%", fontSize: "xx-large",
                                }}>{buttonLabel}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    } else {
        return (
            <motion.div
                variants={variants}
                initial="hidden"
                whileInView="visible"
                transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 1 }}
                viewport={{ margin: "15% 0% -10% 0%" }}
            >
                <div className="info-section" style={divStyle}>
                    <div className="info-wrapper">
                        <div className="wrapper">
                            <h1>{title}</h1>
                            <h2>{date}</h2>
                            <p>{description}</p>
                            <Button variant="contained" disableElevation disabled sx={{
                                color: "#f9f0e8", '&:disabled': {
                                    color: "#f9f0e850",
                                    backgroundColor: "#1f1e1c50"
                                }, fontFamily: "Staatliches", width: "100%", fontSize: "xx-large"
                            }}>{buttonLabel}</Button>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }
}